import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import BEMHelper from "react-bem-helper";

import Layout from "../components/layout";
import SEO from "../components/seo";
import HeaderSolidColor from "../modules/headers/header-solid-color";
import SwiftType from "../components/swift-type";
import moduleConductor from "../modules/module-conductor";
import ModuleCtaBanner from "../modules/module-cta-banner";
import SectionHeading from "../components/section-heading";

const sectionName = "template-products-listing";
const bem = new BEMHelper({
  name: sectionName,
});

const TemplateProductsListing = ({ data, location, pageContext }) => {
  const { contentfulTemplateListingPage: page } = data;
  // console.log("page: ", page);
  const mainImage = page?.mainImage;
  const metaTitle = page?.seoPageTitle || page?.pageTitle;
  const metaDesc = page.seoPageDescription
    ? page.seoPageDescription.seoPageDescription
    : null;

  useEffect(() => {
    // document.getElementById("loader_wrap").classList.add("d-none");
    // document.getElementById("loader_wrap").classList.add("loaded");
  }, []);

  const Loader = () => {
    let loaderDiv = document.getElementById("loader");
    let delay = 0.004;

    for (let x = 0; x < 40; x++) {
      let width = x * 2.5 + "%";
      let div = document.createElement("div");
      loaderDiv.appendChild(div);
      div.style.left = width;
      div.style.top = 0;
      div.style.animationDelay = ((x - 20) * (x - 20) * delay) / 8 + "s";
    }
  };

  const products = data.allContentfulTemplateTeardownReport
    ? data.allContentfulTemplateTeardownReport.nodes
    : null;

  return (
    <Layout location={location}>
      <SEO
        title={metaTitle || "Products | UnitedLex"}
        description={metaDesc}
        url={location.href}
        image={mainImage?.file?.url}
      />
      <SwiftType
        customTitle={metaTitle}
        customDesc={metaDesc}
        customFeatImg={mainImage?.file?.url}
      />
      <HeaderSolidColor
        headerTitle={page.pageTitle}
        headerDescription={page.pageDescription.pageDescription}
      />

      <SectionHeading headline={"All Teardown Reports"} />

      {products && (
        <React.Fragment>
          <div {...bem()}>
            {products &&
              products.map((product, i) => {
                return (
                  <div {...bem("product-card")}>
                    <Link to={`${product.slug}`} {...bem("product-card-flex")}>
                      <div {...bem("product-img-wrap")}>
                        <img
                          src={product.productImage.file.url}
                          {...bem("product-img")}
                          alt={product.productImage.description || ""}
                          title={product.productImage.description || ""}
                        />
                      </div>
                      {/* <Img
                        // fluid={mainImage.fluid}
                        // className="featured-insight__img"
                        {...bem("product-img")}
                        fluid={product.productImage.fluid}
                        // imgStyle={{ width: "100px" }}
                        // objectFit="cover"
                        // objectPosition="50% 50%"
                      /> */}
                      {product.reportType && (
                        <p {...bem("product-label")}>{product.reportType}</p>
                      )}
                      {product.title && (
                        <h2 {...bem("product-title")}>{product.title}</h2>
                      )}
                      {product.listingDescription && (
                        <p {...bem("product-desc")}>
                          {product.listingDescription.listingDescription}
                        </p>
                      )}
                      {product.price && (
                        <p {...bem("product-price")}>${product.price} USD</p>
                      )}
                      <button {...bem("product-button")}>
                        Contact Us for Purchase
                      </button>
                    </Link>
                  </div>
                );
              })}
          </div>
        </React.Fragment>
      )}

      {/* <ModuleCtaBanner
        cta1Headline="Lets Get Started"
        cta1Description={{
          cta1Description:
            "We work with forward-thinking corporate and law firm leaders to thrive in the digital age.",
        }}
        cta1Link={{ ctaLinkText: "Let’s connect" }}
      /> */}
      {page.addCtaBanner && moduleConductor(page.addCtaBanner, 0)}
    </Layout>
  );
};

export default TemplateProductsListing;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulTemplateListingPage(slug: { eq: $slug }) {
      pageTitle
      seoPageTitle
      doNotIndex
      pageDescription {
        pageDescription
      }
      slug
      addCtaBanner {
        ...ModuleCtaBannerFragment
      }
    }
    allContentfulTemplateTeardownReport(
      filter: { title: { ne: null } }
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        __typename
        contentful_id
        title
        slug
        reportType
        listingDescription {
          listingDescription
        }
        price
        productImage {
          file {
            url
          }
          gatsbyImageData(width: 550, formats: JPG)
          # fluid(maxWidth: 550, toFormat: JPG) {
          #  ...GatsbyContentfulFluid
          # }
        }
      }
    }
  }
`;
